import { useContext, useEffect } from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import AppV1 from './v1/App'
import AppV2 from './v2/App'
import { useVersion } from './context/appVersionContext'
import { LanguageContext } from './context/languageContext'
import { checkVersion } from './common/utils/checkUrlVersion'
import { useAuthContext } from './context/authContext'
import { useNavigation } from './context/navigationContext'

const AppRouter = () => {
	const { appVersion, setVersion } = useVersion()
	const location = useLocation()
	const { user } = useAuthContext()
	const { navigateToHome } = useNavigation()
	let navigate = useNavigate()
	const {
		state: { language },
	} = useContext(LanguageContext)

	useEffect(() => {
		if (user.deploymentGroup.portal !== '' && user.deploymentGroup.api !== '') {
			setVersion(user.deploymentGroup.portal, user.deploymentGroup.api)
		}
	}, [user])

	useEffect(() => {
		const versionedPath = checkVersion(
			location.pathname,
			appVersion.path,
			location.search
		)
		if (location.pathname !== versionedPath) {
			navigate(versionedPath, { replace: true })
		}
	}, [location.pathname, appVersion.path, navigate])

	useEffect(() => {
		const accessToken = window.sessionStorage.getItem('accessToken')
		const { pathname } = location

		if (
			!accessToken &&
			(pathname.includes('services') || pathname.includes('statistics'))
		) {
			navigateToHome('v1', language)
		}
	}, [navigate])

	return (
		<Routes>
			<Route path="/v1/*" element={<AppV1 />} />
			<Route path="/v2/*" element={<AppV2 />} />
		</Routes>
	)
}

export default AppRouter
